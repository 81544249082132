import { theme } from "@project/shared"
import { createGlobalStyle } from "styled-components"

const GlobalStyles: any = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family: "Noto Sans JP", serif;

    @media print{
      font-size: 9pt;
      color: black !important;
    }
  }

/* @media screen and (max-height:600px){
   body{
    overflow-y: scroll !important;
  }
} */

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .select-small-popup{
width: auto !important;
    @media screen and (max-width: 618px){
      width: auto !important;
    }
  }


  .select-category-popup{
    width: 250 !important;
  }
  .select_dropdown_width{
    width: fit-content !important;
  }

  .dynamic-dropdown-width {
      min-width: 200px;
      max-width: 800px;

      &.min-w-120{
        min-width: 120px;
      }
      &.min-w-150{
        min-width: 150px;
      }
      &.min-w-160{
        min-width: 160px;
      }
      &.min-w-170{
        min-width: 170px;
      }
      &.min-w-180{
        min-width: 180px;
      }
      &.min-w-250 {
        min-width: 250px;
      }
      &.min-w-300{
        min-width: 300px;
      }
      &.min-w-320{
        min-width: 320px;
      }
      &.min-w-350{
        min-width: 350px;
      }
      @media (max-width: ${theme.breakpoints.sm}) {
        max-width: 100%
      }
    }

  .ant-select-dropdown{
    max-width: calc(100vw - 4px) !important;
  }

  .custom-date-picker {
    height: 40px;
    border: 1px solid #D2D1D1 !important;
    border-radius: 5px;
    width: 160px;
  }

  .custom-rounded-border-modal {
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-header {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background: #06BCD4;
      .ant-modal-title {
        color: white;
      }
    }
    .ant-modal-close {
      color: white;
    }
  }

  .custom-modal-for-add-joint-facility {
    .ant-modal-header {
      background: #06BCD4;
      .ant-modal-title {
        color: white;
      }
    }
    .ant-modal-close {
      color: white;
    }
  }

  .text-data {
      @media print {
      font-size: 9px;
    }
    }

  .flexbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;


  }

  .error-message{
    color: rgb(224, 0, 0);
    padding-bottom: 0;
    padding-top: 1rem;
    font-size: 14px;
  }

  .underline{
    text-decoration: underline;
  }

  /* style for screen mode */
  @media screen {
    .only-print {
      display: none;
    }
  }

  /* style for print mode */
  @media print {

    .ant-table-thead > tr > th {
      outline:1px solid #444748 !important;
    }
  .no-print {
    display: none !important;
  }



  .ant-notification {
    display: none !important;
  }
}

.ant-notification-notice-icon {
    position: absolute;
    font-size: 24px;
    line-height: 24px;
}

.modal-popup {
      width: 40% !important;

      @media screen and (max-width: 1440px){
        width: 60% !important;
      }
      @media screen and (max-width: 768px){
        width: 80% !important;
      }
    }

.ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 27px;
    font-size: 16px;
}



.confirm-modal .ant-modal-footer{
  display: flex;
  justify-content: flex-end;
  button{
    display: flex;
    align-items: center;
  }
}
.ant-modal-wrap.instructor__info-modal {
@media screen and (max-width: 767px) and  (max-height:400px) {
  position: absolute !important;
    top:20px  !important;
    overflow: unset !important;
  }

}



  .ant-select-dropdown-empty {
    text-align: center  !important;
}

.ant-modal-wrap.instructor__occu_info-modal {
  @media screen and (max-width: 767px) and  (max-height:400px) {
    position: absolute !important;
    top:20px  !important;
    overflow: unset !important;
  }

}

/* //editor bubble menu styles */
.editor__bubble_menu {
   border:1px solid gray;
   background-color: #FFFFFF;
    border-radius: 2px;
    display: flex;
    gap:0.5rem;
    margin-left: 70px;
    margin-bottom:-10px;

    &>div{
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:5px 8px;
         svg {
            font-weight: bolder;
            font-size: 1.1rem;
            width: 15px;
            height: 15px;
          }

        svg:hover {
          cursor: pointer;
        }

    }
    div:not(:last-child){
      border-right:1px solid gray !important
    }
    .link__container{
    display: flex;
    gap:0.5rem;
     padding:0 8px;
     color:#000000;
     .icon{
        font-weight: bolder;
        font-size: 1.1rem;
        width: 15px;
        height: 15px;
     }
     a{
          border-left:1px solid gray !important
     }
    }


  }

  .decision-service-list-modal {
    width: 60% !important;
    @media (max-width: 767px) {
      width: 90% !important;
    }
  }

  /* wareki date picker popover */
  .eassy_date_picker {
    .ant-popover-content {
      .ant-popover-inner{
          border-radius: 8px  !important;
        .ant-popover-inner-content {
          padding:8px !important;
          }
      }
    }
  }

  .img-crop-modal.eassy_image_crop_modal{
    .ant-modal-close{
      right:6px !important;
      top:-6px !important
    }
    .ant-modal-header{
      padding:8px 12px !important;
    }
    .ant-modal-body{
      padding:10px !important
    }
    .ant-modal-footer{
    display: flex !important;
    column-gap: 5px !important;
    padding: 3px 12px  !important;
    button{
    width: fit-content !important;
    }
}
    .img-crop-container{
    min-height: 350px !important;
    background-color: transparent !important;
    position: relative !important;
    }
    .reactEasyCrop_Container{
     position: relative !important;
    }
  .img-crop-control.img-crop-control-zoom,
  .img-crop-control.img-crop-control-rotation,
  .img-crop-control.img-crop-control-aspect{
      display: flex !important;
      width: 100% !important;
      column-gap: 10px !important;
      margin-top: 16px !important;
      .ant-slider{
        width:100% !important;
      }
      button{
        background-color: ${theme.gray2} !important;
        padding:4px 8px !important;
        border:none !important;
      }
  }
  .img-crop-control.img-crop-control-aspect{
    margin-bottom: 0 !important;
  }

  }

   

`

export { GlobalStyles }
